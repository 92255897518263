/**
* Columns-Plugin
* https://github.com/getkirby-plugins/columns-plugin
*/


$columns-spacing: 1rem;


.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  width: 100%;
  vertical-align: top;
}

// Calculate respective min-widths for n-sized columns to wrap on one line
@for $n from 1 through 5 {
  @media (min-width: $n * 15rem) {
    .columns.columns-#{$n} {
      flex-wrap: nowrap;

      > .column {
        width: calc(#{100% / $n} - #{$columns-spacing});
      }
    }
  }
}


/* Enlarge Tables in Columns */

.column table {
  width: 100%;
}


/* Modifications in Article */

// Enlarge columns left & right
// .c-article .columns {
//   margin: 0 #{-1/2 * $columns-spacing - 1.5rem};
// }

// Always show all columns next to each other (as intended) when article has reached it's max-width
// @media (min-width: 70rem) {
//   .c-article .columns {
//     // flex-wrap: nowrap;
//   }
// }
