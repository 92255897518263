$tag-spacing-lr: 0.25em;
$tag-spacing-tb: 0.4em;
$tag-border-radius: 10em;
$tag-border-width: 2px;

.c-tags {
  // Reverse left/right margin of inner tags
  cursor: default;
  margin: 2em -#{$tag-spacing-lr} -#{$tag-spacing-tb} -#{$tag-spacing-lr};
  padding-bottom: 2em;
}

.c-tag {
  cursor: default;
  @include font-menu;
  font-size: .8rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: .5px;
  display: inline-block;
  color: white;
  padding: 0 1em;
  line-height: 2;
  margin: $tag-spacing-tb $tag-spacing-lr;
  border-radius: $tag-border-radius;
  background: $color-page-text;
  border: none;
}

// Styling for Links as Tags
a.c-tag, button.c-tag {
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  color: $color-page-text;
  background: $color-gray-ultralight;
  transition: all 0s ease-in-out;

  &:not(.c-tag--isDisabled):hover, &:not(.c-tag--isDisabled):focus {
    background: $color-highlight;
    color: white;
  }

  &--isActive {
    background: $color-page-text !important;
    color: white;
  }

  &--isDisabled {
    cursor: default;
    pointer-events: none;
    opacity: .2;
    position: relative;
    outline: 0;

    &:after {
      content: "";
      position: absolute;

      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      border-radius: $tag-border-radius;
      border: $tag-border-width currentColor solid;
    }
  }
}
