$grid-spacing: 1rem;
$grid-spacing: 10px;
$grid-item-padding: 1rem;

.c-grid-gallery {
  position: relative;
  margin: - 1/2 * $grid-spacing;

  h2 + & {
    padding-top: 1em;
  }
}

.c-grid-gallery__item {
  width: 100%;
  margin: $grid-spacing / 2;

  @media (min-width: 25rem) {
    width: calc(50% - #{2 * $grid-spacing});
  }
  @media (min-width: 50rem) {
    width: calc(33.3333% - #{3 * $grid-spacing});
  }

  .no-js & {
    margin-left: auto;
    margin-right: auto;
  }

  overflow: hidden;
  border-radius: $base-border-radius;
  transform: translate3d(0,0,0);

  a {
    display: block;
    position: relative;
    cursor: zoom-in;
  }
}

.c-grid-gallery__item img {
  background: $color-gray-ultralight;
  will-change: transform;
  transition: all .2s ease-in-out !important;
}

.c-grid-gallery__item__title {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  padding: $grid-item-padding;
  color: rgba(255,255,255,.8);
  line-height: 1.5;
  letter-spacing: .5px;
  font-style: italic;

  // Hide this completely if image has no title
  .c-grid-gallery__item--hasNoTitle & {
    display: none;
  }

  // Dark Underlays (for Indicator-Icon & Title-Text)
  &:before,
  &:after {
    z-index: 10;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all .2s ease-in-out;
    will-change: transform;
  }
  &:before {
    width: 6rem;
    height: 6rem;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    background: rgba(0,0,0,1);
    filter: blur(25px);
  }
  &:after {
    width: 100%;
    height: 5rem;
    background: linear-gradient(0deg, rgba(0,0,0,1) 5%,  rgba(0,0,0,0) 100%);
    opacity: 0;
  }
}

.c-grid-gallery__item__title-text {
  z-index: 20;
  position: absolute;
  bottom: $grid-item-padding;
  left: $grid-item-padding;
  width: 80%;
  width: calc(100% - 2 * #{$grid-item-padding});
  opacity: 0;
  transition: all .2s ease-in-out;
  transform: translateY(5px);

  // Only Show Image-Title or Caption (<h1>) and normalize it
  h1 {
    @include font-body;
    font-size: 1em;
    margin: 0;

    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  *:not(h1) {
    @include visually-hidden();
  }
}

.c-grid-gallery__item__title-indicator {
  z-index: 20;
  position: absolute;
  left: $grid-item-padding;
  bottom: $grid-item-padding;
  transform: translateY(-0.15em);
  width: 1em;
  height: 1em;
  transition: all .2s ease-in-out;
}

.c-grid-gallery__item:hover,
.c-grid-gallery__item:focus {
  // Hide Indicator and it's shadow
  .c-grid-gallery__item__title:before,
  .c-grid-gallery__item__title-indicator {
    opacity: 0;
  }
  // Fade-In Title-Text and it's shadow
  .c-grid-gallery__item__title:after,
  .c-grid-gallery__item__title-text {
    opacity: 1;
    transform: none;
  }

  .is-loaded img {
    transform: translate3d(0,0,0) scale(1.03);
  }
}
