@charset "UTF-8";
/**
* Mixins for consistent typography and FOIT all over the place.
* Using FontFaceObserver critical javascript.
*/
/* Styles */
/* Sizing */
/**
 * Truncate Text-Overflow
 */
/**
* Break Words (esp. links) and don't overflow container.
* Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
**/
/**
* Custom Multiline Underlined Links.
* NOTE: Multiline only works with `display: inline;`
*/
/**
* Custom Underlined Links.
* ADVANTAGES: Easily adapt currentColor to apply hover-effect & Icon-Offset possible.
* DISADVANTAGE: No line-breaks possible.
*/
/**
* Skips the icon of the above underlined-link. (Not the multiline-one)
*/
/**
* Mixin for creating smart underlining.
* Text-shadows are added to make the line look paused.
*/
/**
* Columns-Plugin
* https://github.com/getkirby-plugins/columns-plugin
*/
.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.column {
  width: 100%;
  vertical-align: top; }

@media (min-width: 15rem) {
  .columns.columns-1 {
    flex-wrap: nowrap; }
    .columns.columns-1 > .column {
      width: calc(100% - 1rem); } }

@media (min-width: 30rem) {
  .columns.columns-2 {
    flex-wrap: nowrap; }
    .columns.columns-2 > .column {
      width: calc(50% - 1rem); } }

@media (min-width: 45rem) {
  .columns.columns-3 {
    flex-wrap: nowrap; }
    .columns.columns-3 > .column {
      width: calc(33.33333% - 1rem); } }

@media (min-width: 60rem) {
  .columns.columns-4 {
    flex-wrap: nowrap; }
    .columns.columns-4 > .column {
      width: calc(25% - 1rem); } }

@media (min-width: 75rem) {
  .columns.columns-5 {
    flex-wrap: nowrap; }
    .columns.columns-5 > .column {
      width: calc(20% - 1rem); } }

/* Enlarge Tables in Columns */
.column table {
  width: 100%; }

/* Modifications in Article */
/**
* Extra Styling for articles (default text-pages)
*/
/* Misc */
.c-section.c-article {
  padding-bottom: 4em; }

.c-section.c-article main {
  padding-bottom: 1em; }

.c-article main {
  max-width: 80ch; }

/* Links */
.c-article main a {
  text-decoration: none;
  background-image: linear-gradient(to top, transparent 0%, rgba(226, 104, 70, 0.5) 0%, rgba(226, 104, 70, 0.5) 1px, transparent 1px);
  padding-bottom: 2px; }
  .c-article main a:hover, .c-article main a:focus {
    background-image: linear-gradient(to top, transparent 0%, #E26846 0%, #E26846 1px, transparent 1px); }

/* Figures */
.c-article main figure {
  float: left;
  margin: 1.5em 0; }

/* Blockquotes */
.c-article main blockquote {
  font-size: 17px;
  font-size: 1.0625em;
  line-height: 1.64706;
  line-height: 1.9;
  color: #424242;
  position: relative;
  max-width: 80ch;
  border-left: 2px #F1F1F1 solid;
  padding-left: 1.5rem;
  margin: 1.5rem 0;
  font-style: italic; }
  .c-article main blockquote p:first-child {
    margin-top: 0;
    padding-top: 0.5rem; }
  .c-article main blockquote p:last-child {
    margin-bottom: 0;
    padding-bottom: 0.5rem; }

/* Tables */
.c-article main table {
  max-width: 100%;
  margin: 1rem 0; }
  .c-article main table thead tr {
    background: #1a1a1a;
    color: #F1F1F1;
    font-family: 'Monaco', 'Courier New', monospace;
    font-weight: normal; }
    .fonts-loaded--sourcecodepro .c-article main table thead tr {
      font-family: 'Source Code Pro', 'Monaco', 'Courier New', monospace; }
  .c-article main table th {
    padding: .6em 1em;
    font-weight: normal; }
  .c-article main table tr {
    background: #F9F9F9; }
  .c-article main table td {
    padding: .5em 1em; }

.c-article main table th:first-of-type {
  border-top-left-radius: 3px; }

.c-article main table th:last-of-type {
  border-top-right-radius: 3px; }

.c-article main table :not(thead) tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 3px; }

.c-article main table :not(thead) tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 3px; }

.c-article .c-section:nth-of-type(even) main table :not(thead) tr {
  background: white; }

/* Footnotes */
.c-article main .footnotes {
  font-size: 15px;
  font-size: 0.9375em;
  line-height: 1.86667; }
  .c-article main .footnotes p {
    margin: 0; }
  .c-article main .footnotes hr {
    margin-top: 3rem;
    margin-bottom: 2rem; }
  .c-article main .footnotes ol {
    padding-left: 1.5em; }
  .c-article main .footnotes li {
    color: #7C7C7C; }
    .c-article main .footnotes li:target {
      color: #424242; }
    .c-article main .footnotes li:not(:last-of-type) {
      margin-bottom: .5rem; }

.c-article main sup {
  padding: 0 .3em .2em .3em; }
  .c-article main sup a {
    font-family: 'Monaco', 'Courier New', monospace;
    font-weight: normal;
    font-weight: 600;
    background-image: none !important;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    line-height: inherit; }
    .fonts-loaded--sourcecodepro .c-article main sup a {
      font-family: 'Source Code Pro', 'Monaco', 'Courier New', monospace; }
    .c-article main sup a:after {
      content: "";
      display: block;
      position: absolute;
      left: -1px;
      bottom: 2px;
      width: 100%;
      width: calc(100% + 2px);
      height: 0;
      border-bottom: 1px currentColor solid;
      opacity: .2; }
    .c-article main sup a:hover:after, .c-article main sup a:focus:after {
      opacity: 1; }
  .c-article main sup:target {
    border-radius: 3px;
    background: #FCFF82; }
    .c-article main sup:target a {
      text-decoration: none;
      background-image: none;
      color: black; }

.c-tags {
  cursor: default;
  margin: 2em -0.25em -0.4em -0.25em;
  padding-bottom: 2em; }

.c-tag {
  cursor: default;
  font-family: 'Monaco', 'Courier New', monospace;
  font-weight: normal;
  font-size: .8rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: .5px;
  display: inline-block;
  color: white;
  padding: 0 1em;
  line-height: 2;
  margin: 0.4em 0.25em;
  border-radius: 10em;
  background: #2c2d34;
  border: none; }
  .fonts-loaded--sourcecodepro .c-tag {
    font-family: 'Source Code Pro', 'Monaco', 'Courier New', monospace; }

a.c-tag, button.c-tag {
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  color: #2c2d34;
  background: #F9F9F9;
  transition: all 0s ease-in-out; }
  a.c-tag:not(.c-tag--isDisabled):hover, a.c-tag:not(.c-tag--isDisabled):focus, button.c-tag:not(.c-tag--isDisabled):hover, button.c-tag:not(.c-tag--isDisabled):focus {
    background: #E26846;
    color: white; }
  a.c-tag--isActive, button.c-tag--isActive {
    background: #2c2d34 !important;
    color: white; }
  a.c-tag--isDisabled, button.c-tag--isDisabled {
    cursor: default;
    pointer-events: none;
    opacity: .2;
    position: relative;
    outline: 0; }
    a.c-tag--isDisabled:after, button.c-tag--isDisabled:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 10em;
      border: 2px currentColor solid; }

/* A button component (with primary-style) */
.c-action-btns {
  margin: -.5em !important;
  padding: 1em 0; }
  .c-action-btns .c-action-btn {
    margin: .5em .5em; }
  .c-action-btns--noBottomPadding {
    padding: 1em 0 0 0; }

.c-action-btn {
  font-family: 'Monaco', 'Courier New', monospace;
  font-weight: normal;
  font-size: .9em;
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: .6em 1.2em;
  text-align: center;
  user-select: none;
  cursor: pointer;
  color: #424242;
  border: 0;
  border-radius: 3px;
  background: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  transition: opacity 0.2s ease-in-out, box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  text-decoration: none; }
  .fonts-loaded--sourcecodepro .c-action-btn {
    font-family: 'Source Code Pro', 'Monaco', 'Courier New', monospace; }
  .c-action-btn:hover, .c-action-btn:focus {
    transform: translateY(-1px);
    opacity: .9;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.05); }
  .c-action-btn:active {
    transition-duration: 0s;
    transform: none;
    background: #fafafa;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1); }

/* Primary-Button */
.c-action-btn--isPrimary {
  letter-spacing: .2px;
  color: white;
  background: #E26846; }
  .c-action-btn--isPrimary:active {
    background: #df5a34; }

.c-action-btn--isFacebook {
  color: #3b5998; }

/* A special looking link component (with or without icon) */
.c-nice-links {
  margin: -0.75rem -0.75rem;
  font-size: 1rem; }
  .c-nice-links--topSpacing {
    padding-top: 1.5rem; }
  .c-nice-links button.c-nice-link,
  .c-nice-links a.c-nice-link,
  .c-nice-links .c-nice-link {
    margin: 0.75rem 0.75rem; }

button.c-nice-link,
a.c-nice-link,
.c-nice-link {
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  line-height: inherit;
  font-family: 'Monaco', 'Courier New', monospace;
  font-weight: normal;
  display: inline-block;
  align-items: center;
  margin: 0;
  color: #E26846;
  line-height: 100%; }
  button.c-nice-link:after,
  a.c-nice-link:after,
  .c-nice-link:after {
    content: "";
    display: block;
    position: absolute;
    left: -1px;
    bottom: -2px;
    width: 100%;
    width: calc(100% + 2px);
    height: 0;
    border-bottom: 1px currentColor solid;
    opacity: .2; }
  button.c-nice-link:hover:after, button.c-nice-link:focus:after,
  a.c-nice-link:hover:after,
  a.c-nice-link:focus:after,
  .c-nice-link:hover:after,
  .c-nice-link:focus:after {
    opacity: 1; }
  .fonts-loaded--sourcecodepro button.c-nice-link, .fonts-loaded--sourcecodepro
  a.c-nice-link, .fonts-loaded--sourcecodepro
  .c-nice-link {
    font-family: 'Source Code Pro', 'Monaco', 'Courier New', monospace; }
  button.c-nice-link--hasIcon:after,
  a.c-nice-link--hasIcon:after,
  .c-nice-link--hasIcon:after {
    width: calc(100% + 2px - 1.2rem - 0.5rem) !important;
    left: calc(-1px + 1.2rem + 0.5rem) !important; }
  button.c-nice-link--hasIcon .o-icon,
  a.c-nice-link--hasIcon .o-icon,
  .c-nice-link--hasIcon .o-icon {
    top: -0.075rem;
    position: relative;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: middle;
    margin-right: 0.5rem; }
  button.c-nice-link--hasIconRight:after,
  a.c-nice-link--hasIconRight:after,
  .c-nice-link--hasIconRight:after {
    width: calc(100% + 2px - 1.2rem - 0.5rem) !important;
    left: auto;
    right: calc(-1px + 1.2rem + 0.5rem) !important; }
  button.c-nice-link--hasIconRight .o-icon,
  a.c-nice-link--hasIconRight .o-icon,
  .c-nice-link--hasIconRight .o-icon {
    top: -0.075rem;
    position: relative;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: middle;
    margin-left: 0.5rem; }
  button.c-nice-link--isFacebook,
  a.c-nice-link--isFacebook,
  .c-nice-link--isFacebook {
    color: #3b5998; }
  button.c-nice-link--isWikipedia,
  a.c-nice-link--isWikipedia,
  .c-nice-link--isWikipedia {
    color: black; }
  button.c-nice-link + .c-nice-link-undertitle,
  a.c-nice-link + .c-nice-link-undertitle,
  .c-nice-link + .c-nice-link-undertitle {
    font-family: 'Monaco', 'Courier New', monospace;
    font-weight: normal;
    font-size: .8em;
    color: #7C7C7C;
    margin-left: 1.7rem;
    margin-top: -0.825rem;
    margin-bottom: 0.45rem; }
    .fonts-loaded--sourcecodepro button.c-nice-link + .c-nice-link-undertitle, .fonts-loaded--sourcecodepro
    a.c-nice-link + .c-nice-link-undertitle, .fonts-loaded--sourcecodepro
    .c-nice-link + .c-nice-link-undertitle {
      font-family: 'Source Code Pro', 'Monaco', 'Courier New', monospace; }

/* A component styling the HTML <table> */
.c-grid-gallery {
  position: relative;
  margin: -5px; }
  h2 + .c-grid-gallery {
    padding-top: 1em; }

.c-grid-gallery__item {
  width: 100%;
  margin: 5px;
  overflow: hidden;
  border-radius: 3px;
  transform: translate3d(0, 0, 0); }
  @media (min-width: 25rem) {
    .c-grid-gallery__item {
      width: calc(50% - 20px); } }
  @media (min-width: 50rem) {
    .c-grid-gallery__item {
      width: calc(33.3333% - 30px); } }
  .no-js .c-grid-gallery__item {
    margin-left: auto;
    margin-right: auto; }
  .c-grid-gallery__item a {
    display: block;
    position: relative;
    cursor: zoom-in; }

.c-grid-gallery__item img {
  background: #F9F9F9;
  will-change: transform;
  transition: all .2s ease-in-out !important; }

.c-grid-gallery__item__title {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  padding: 1rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.5;
  letter-spacing: .5px;
  font-style: italic; }
  .c-grid-gallery__item--hasNoTitle .c-grid-gallery__item__title {
    display: none; }
  .c-grid-gallery__item__title:before, .c-grid-gallery__item__title:after  {
    z-index: 10;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all .2s ease-in-out;
    will-change: transform; }
  .c-grid-gallery__item__title:before  {
    width: 6rem;
    height: 6rem;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    background: black;
    filter: blur(25px); }
  .c-grid-gallery__item__title:after  {
    width: 100%;
    height: 5rem;
    background: linear-gradient(0deg, black 5%, rgba(0, 0, 0, 0) 100%);
    opacity: 0; }

.c-grid-gallery__item__title-text {
  z-index: 20;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: 80%;
  width: calc(100% - 2 * 1rem);
  opacity: 0;
  transition: all .2s ease-in-out;
  transform: translateY(5px); }
  .c-grid-gallery__item__title-text h1 {
    font-family: 'Georgia', serif;
    font-size: 1em;
    margin: 0;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .c-grid-gallery__item__title-text *:not(h1) {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px; }

.c-grid-gallery__item__title-indicator {
  z-index: 20;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  transform: translateY(-0.15em);
  width: 1em;
  height: 1em;
  transition: all .2s ease-in-out; }

.c-grid-gallery__item:hover .c-grid-gallery__item__title:before,
.c-grid-gallery__item:hover .c-grid-gallery__item__title-indicator,
.c-grid-gallery__item:focus .c-grid-gallery__item__title:before,
.c-grid-gallery__item:focus .c-grid-gallery__item__title-indicator {
  opacity: 0; }

.c-grid-gallery__item:hover .c-grid-gallery__item__title:after,
.c-grid-gallery__item:hover .c-grid-gallery__item__title-text,
.c-grid-gallery__item:focus .c-grid-gallery__item__title:after,
.c-grid-gallery__item:focus .c-grid-gallery__item__title-text {
  opacity: 1;
  transform: none; }

.c-grid-gallery__item:hover .is-loaded img,
.c-grid-gallery__item:focus .is-loaded img {
  transform: translate3d(0, 0, 0) scale(1.03); }

/**
* Overwrite Styling for Cookiebanner
* https://github.com/dobarkod/cookie-banner
*/
div.cookiebanner,
div.cookiebanner > span,
div.cookiebanner div.cookiebanner-close {
  font-family: 'Monaco', 'Courier New', monospace;
  font-weight: normal;
  font-size: .9em;
  color: #cbcbcb; }
  .fonts-loaded--sourcecodepro div.cookiebanner, .fonts-loaded--sourcecodepro
  div.cookiebanner > span, .fonts-loaded--sourcecodepro
  div.cookiebanner div.cookiebanner-close {
    font-family: 'Source Code Pro', 'Monaco', 'Courier New', monospace; }

div.cookiebanner {
  padding: 0.5rem 3.125rem 0.5rem 0.625rem !important;
  background: rgba(0, 0, 0, 0.85) !important; }

div.cookiebanner a {
  color: #989898 !important;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  line-height: inherit;
  white-space: nowrap; }
  div.cookiebanner a:after {
    content: "";
    display: block;
    position: absolute;
    left: -1px;
    bottom: -2px;
    width: 100%;
    width: calc(100% + 2px);
    height: 0;
    border-bottom: 1px currentColor solid;
    opacity: .2; }
  div.cookiebanner a:hover:after, div.cookiebanner a:focus:after {
    opacity: 1; }

div.cookiebanner div.cookiebanner-close {
  background: white;
  color: black;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  margin-top: -0.625rem;
  right: 1.25rem; }
