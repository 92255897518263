/* A special looking link component (with or without icon) */

$nice-link-h-spacing: .75rem;
$nice-link-v-spacing: .75rem;

$nice-link-icon-size: 1.2rem;
$nice-link-icon-margin: .5rem;


.c-nice-links {
  margin: #{- $nice-link-v-spacing} #{- $nice-link-h-spacing};
  font-size: 1rem;

  &--topSpacing {
    padding-top: $base-spacing-unit;
  }

  button.c-nice-link,
  a.c-nice-link,
  .c-nice-link {
    margin: $nice-link-v-spacing $nice-link-h-spacing;
  }
}

button.c-nice-link,
a.c-nice-link,
.c-nice-link {
  @include underlined-link;
  @include font-menu;
  display: inline-block;
  align-items: center;
  margin: 0;
  color: $color-page-link;
  line-height: 100%;


  // Modify underlined link to skip icon
  &--hasIcon {
    @include underlined-link--skipIcon($nice-link-icon-margin, $nice-link-icon-size);
  }
  &--hasIconRight {
    @include underlined-link--skipIcon($nice-link-icon-margin, $nice-link-icon-size, false);
  }

  // Specially colored links
  &--isFacebook {
    color: $color-brand-facebook;
  }
  &--isWikipedia {
    color: black;
  }

  // Undertitle
  & + .c-nice-link-undertitle {
    @include font-menu;
    font-size: .8em;
    color: $color-gray;
    margin-left:$nice-link-icon-size + $nice-link-icon-margin;
    margin-top: - $nice-link-v-spacing - .075rem;
    margin-bottom: $nice-link-v-spacing - .3rem;
  }
}

