/* A button component (with primary-style) */

$btn-bg: white;
$btn-primary-bg: $color-highlight;


// A wrapper containing multiple buttons
.c-action-btns {
  margin: -.5em !important;
  padding: 1em 0;
  .c-action-btn {
    margin: .5em .5em;
  }
  &--noBottomPadding {
    padding: 1em 0 0 0;
  }
}

// The actual button
.c-action-btn {
  @include font-menu;
  font-size: .9em;

  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: .6em 1.2em;
  text-align: center;
  user-select: none;
  cursor: pointer;

  color: $color-gray-dark;
  border: 0;
  border-radius: $base-border-radius;
  background: $btn-bg;
  box-shadow: 0 1px 4px 0 rgba(#000, .1), 0 1px 1px 0 rgba(#000, .05);

  $transition-options: .2s ease-in-out;
  transition: opacity #{$transition-options}, box-shadow #{$transition-options}, transform #{$transition-options};
  transform: translate3d(0,0,0);
  will-change: transform;
  text-decoration: none;

  // Button-States
  &:hover,
  &:focus {
    transform: translateY(-1px);
    opacity: .9;
    box-shadow: 0 1px 6px 0 rgba(#000, .1), 0 1px 1px 0 rgba(#000, .05);
  }
  &:active {
    transition-duration: 0s;
    transform: none;

    background: darken($btn-bg, 2%);
    box-shadow: 0 1px 2px 0 rgba(#000, .1);
  }
}


/* Primary-Button */
.c-action-btn--isPrimary {
  letter-spacing: .2px;
  color: white;
  background: $btn-primary-bg;

  &:active {
    background: darken($btn-primary-bg, 4);
  }
}

.c-action-btn--isFacebook {
  color: $color-brand-facebook;
}
