// Clearfix
@mixin clearfix($important: false) {
    // Determine whether or not to make declarations important.
    @if ($important == false) {
        $important: null;
    }
    @else {
        $important: !important;
    }

    &:after {
        content: '' $important;
        display: table $important;
        clear: both $important;
    }
}


// Visually hides an element but still accessible
@mixin visually-hidden() {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
}


// Icon-Object with different sizes
// Source: http://blog.cloudfour.com/our-svg-icon-process/
@mixin o-icon($size: 1em) {
  // Align more nicely with capital letters
  $top-factor: -.0625;
  top: $top-factor * $size;
  position: relative;
  // Flow with text content
  display: inline-block;
  // Use the parent font-size for width and height
  width: $size;
  height: $size;
  // Vertically align icon with adjacent text
  vertical-align: middle;
}
