/**
* Extra Styling for articles (default text-pages)
*/


/* Misc */

// Decrease Section-Padding to give `<main>` a share
// (e.g. for `c-nice-links` in an event-page)
.c-section.c-article {
  padding-bottom: 4em;
}
.c-section.c-article main {
  padding-bottom: 1em;
}


// Max-Width for more comfortable reading-experience
.c-article main {
  max-width: 80ch;
}


/* Links */

.c-article main a {
  @include underlined-link-multiline;
}


/* Figures */

.c-article main figure {
  float: left;
  margin: 1.5em 0;
}


/* Blockquotes */

.c-article main blockquote {
  @include font-size(17px);
  line-height: 1.9;
  color: $color-gray-dark;
  position: relative;
  max-width: 80ch;
  border-left: 2px $color-gray-light solid;
  padding-left: $base-spacing-unit;
  margin: $base-spacing-unit 0;
  font-style: italic;

  p:first-child {
    margin-top: 0;
    padding-top: $base-spacing-unit-small;
  }
  p:last-child {
    margin-bottom: 0;
    padding-bottom: $base-spacing-unit-small;
  }
}


/* Tables */

.c-article main table {
  max-width: 100%;
  margin: 1rem 0;

  thead tr {
    background: $color-gray-ultradark;
    color: $color-gray-light;
    @include font-menu();
  }
  th {
    padding: .6em 1em;
    font-weight: normal;
  }
  tr {
    background: $color-gray-ultralight;
  }
  td {
    padding: .5em 1em;
  }
}

// Border-Radius of outer Corners
.c-article main table {
  th:first-of-type {
    border-top-left-radius: $base-border-radius;
  }
  th:last-of-type {
    border-top-right-radius: $base-border-radius;
  }
  :not(thead) tr:last-of-type td:first-of-type {
    border-bottom-left-radius: $base-border-radius;
  }
  :not(thead) tr:last-of-type td:last-of-type {
    border-bottom-right-radius: $base-border-radius;
  }
}

// Alternate Table-Background with section-background
.c-article .c-section:nth-of-type(even) main table :not(thead) tr {
  background: white;
}


/* Footnotes */

.c-article main .footnotes {
  @include font-size(15px);
  p {
    margin: 0;
  }
  hr {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  ol {
    padding-left: 1.5em;
  }
  li {
    color: $color-gray;

    &:target {
      color: $color-gray-dark;
    }
    &:not(:last-of-type){
      margin-bottom: .5rem;
    }
  }
}

// Footnote Links
.c-article main sup {
  padding: 0 .3em .2em .3em;

  a {
    @include font-menu();
    font-weight: 600;
    background-image: none !important;
    @include underlined-link(-2px)
  }

  &:target {
    border-radius: 3px;
    background: $color-highlight-yellow;
    a {
      text-decoration: none;
      background-image: none;
      color: black;
    }
  }
}
