/**
* Overwrite Styling for Cookiebanner
* https://github.com/dobarkod/cookie-banner
*/


$close-circle-size: 1.25rem;
$close-icon-size: 1.5rem;
$text-color: darken($color-gray-light, 15%);


div.cookiebanner,
div.cookiebanner > span,
div.cookiebanner div.cookiebanner-close {
  @include font-menu;
  font-size: .9em;
  color: $text-color;
}

div.cookiebanner {
  padding: .5rem 2.5*$close-circle-size .5rem .5*$close-circle-size !important;
  background: rgba(0,0,0,.85) !important;
}

div.cookiebanner a {
  color: darken($text-color, 20%) !important;
  @include underlined-link();
  white-space: nowrap;
}

div.cookiebanner div.cookiebanner-close {
  background: white;
  color: black;
  border-radius: 50%;
  width: $close-circle-size;
  height: $close-circle-size;
  line-height: $close-circle-size;

  font-size: $close-icon-size;
  position: absolute;
  top: 50%;
  margin-top: -#{$close-circle-size / 2};
  right: $close-circle-size;
}
